import React from 'react'
import './footer.css'
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <a href="" className='footer__logo'>Richard Hubbert</a>
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        {/* <li><a href="#services">Services</a></li> */}
        <li><a href="#portfolio">Portfolio</a></li>
        {/* <li><a href="#testimonials">Testimonials</a></li> */}
        <li><a href="#contact">Contact</a></li>
      </ul>
      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/richard-hubbert-bb05b49/edIn" target='_blank'><FaLinkedinIn /></a>
        <a href="https://www.facebook.com/richard.hubbert.14" target='_blank'><FaFacebook /></a>
      </div>
      <div className="footer__copyright">
        <small>&copy; 2024 | Richard Hubbert | All Rights Reserved</small>
      </div>
    </footer>
  )
}

export default Footer