import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/sc_0007_Screenshot 2021-01-19 at 11.12.57.jpg'
import IMG2 from '../../assets/sc_0015_Screenshot 2021-01-19 at 11.11.34.jpg'
import IMG3 from '../../assets/sc_0016_Screenshot 2021-01-19 at 11.11.23.jpg'
import IMG4 from '../../assets/sc_0013_Screenshot 2021-01-19 at 11.11.59.jpg'
import IMG5 from '../../assets/sc_0001_Screenshot 2021-01-19 at 11.13.51.jpg'
import IMG6 from '../../assets/sc_0012_Screenshot 2021-01-19 at 11.12.10.jpg'

// import {ReactComponent as ReactLogo} from '../../assets/mi6.svg';


const data = [
  {
    id: 1,
    image: IMG1,
    title: 'SAB Miller Bottling Plant',
    review: 'hdfhsdkhfhds',
 /*    github: 'https://github.com',*/
    demo: 'https://chasesidedesign.com/' 
  },
  {
    id: 2,
    image: IMG2,
    title: 'Dunhill Learning Portal',
/*     github: 'https://github.com',
    demo: 'https://chasesidedesign.com/' */
  },
  {
    id: 3,
    image: IMG3,
    title: 'Nissan Qashkai World',
/*     github: 'https://github.com',
    demo: 'https://chasesidedesign.com/' */
  },
  {
    id: 4,
    image: IMG4,
    title: 'Addleshaw Goddard Portal',
/*     github: 'https://github.com',
    demo: 'https://chasesidedesign.com/' */
  },
  {
    id: 5,
    image: IMG5,
    title: 'SAB Miller Portal',
/*     github: 'https://github.com',
    demo: 'https://chasesidedesign.com/' */
  },
  {
    id: 6,
    image: IMG6,
    title: 'Addleshaw Goddard Portal',
/*     github: 'https://github.com',
    demo: 'https://chasesidedesign.com/' */
  },
]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id,image,title,github,demo}) => {
            return (
              <article key={id}className='portfolio__item'>
                <div className='portfolio__item-image'>
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className='portfolio__item-cta'>
{/*                   <a href={github} className='btn' target='_blank' rel='noreferrer'>Github</a>
                  <a href={demo} className='btn btn-primary' target='_blank' rel='noreferrer'>Live Demo</a> */}

                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio