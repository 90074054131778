import React from 'react'
import CV from '../../assets/richardHubbertCV.pdf'

const CTA = () => {
  return (
    <div className='cta'>
      <a href={CV} download className='btn'>Download CV</a>
      <a href='https://chasesidedesign.com/clientsSite/clients.html' target="_blank" className='btn btn-primary'>Live Assets</a>
      <a href='https://chasesidedesign.com/clientsSite/pdf/richard-hubbert-portfolio_2024-3.pdf' target="_blank" className='btn'>Portfolio</a>
    </div>
  )
}

export default CTA