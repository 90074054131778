import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/laptopGirlWhite_001.png'
import HeaderSocials from './HeaderSocials'


const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Richard</h1>
        <h5 className='text-light'>UI | UX Designer - Developer</h5>
        <CTA />
        <HeaderSocials />


                <div className="header__me">
                  <div className="header__me-image">
                    <img src={ME} alt='About Image' />
                  </div>
                 </div>  
        <a href='#contact' className='scroll__down'>Scroll Down</a>
      </div>
    </header>  
  )
}

export default Header