import React from 'react'
import './about.css'
import ME from '../../assets/laptopGirlWhite_001.png'
import { FiAward } from "react-icons/fi";
import { FaUsers } from "react-icons/fa";
import { FaRegFolder } from "react-icons/fa";

const About = () => {
  return (
    <section id='about'>
      <h5>Get to know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt='About Image' />
          </div>
        </div>  
        <div className="about__content">
          <div className="about__cards">

            <article className='about__card'>
            <FiAward className='about__icon'/>
              <h5>Experience</h5>
              <small>20 Years Experience</small>
            </article>

            <article className='about__card'>
            <FaUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>200+ Clients Worldwide</small>
            </article>
            
            <article className='about__card'>
            <FaRegFolder className='about__icon'/>
              <h5>Projects</h5>
              <small>80+ Completed</small>
            </article>
            
          </div>
          <p>
          As an award-winning UI/UX Designer with extensive experience across various industries, I have consistently demonstrated my ability to enhance user engagement and satisfaction. At National Grid ESO, where I serve as a Principal UI/UX Designer, I have successfully led comprehensive needs analyses, developed intuitive design systems, and delivered impactful design solutions that have driven efficiency and cost savings. My role involved creating cohesive UI/UX designs for platforms working closely with stakeholders and clients, which resulted in significant improvements in user interaction and business alignment.</p>

          <p>
          My experience extends to consulting roles with prominent organisations such as Lloyds Bank and the Home Office/Foreign Office. At Barclays and Barclaycard, I contributed to the design and development of a comprehensive design system, enhancing digital experiences across various platforms. Similarly, my work with the Home Office/Foreign Office involved designing and launching user-friendly portals and multimedia content, significantly improving user satisfaction and engagement.
          </p>
          <p>
          In addition to my practical experience, I am proficient in a wide range of design tools and technologies, including React, React Native, Figma, Adobe Creative Cloud, and various eLearning platforms. My expertise in wireframing, prototyping, and user-centered design principles has been instrumental in delivering high-quality, user-centric digital solutions. Furthermore, I am passionate about staying current with industry trends and continuously refining my skills to embrace emerging design methodologies.

          </p>

          <a href='#contact' className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About