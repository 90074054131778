import React from 'react'
import './experience.css'
import { BsPatchCheck } from "react-icons/bs";

const Experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I have</h5>
      <h2>My Experience
        <div className="container experience__container">
          <div className="experience__frontend">
            <h3>Frontend Development</h3>
            <div className="experience__content">
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>HTML CSS JS</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>React</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>CSS</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>Evolve</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>Webflow</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>Animate</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>Figma</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>Sketch</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
            </div>


          </div>

          <div className="experience__frontend">
            <h3>Media</h3>
            <div className="experience__content">
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>Adobe CC</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>ToonBoom</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>Logic</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>Protools</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>Sibelius</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>Final Cut</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>Pen & Ink</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsPatchCheck className='experience__details-icom'/>
                <div>
                  <h4>Pencil</h4>
                  <small className=' text-light'>Experienced</small>
                </div>
              </article>
            </div>


          </div>








        </div>
      </h2>
    </section>
  )
}

export default Experience